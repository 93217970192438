importScripts('https://storage.googleapis.com/workbox-cdn/releases/6.0.2/workbox-sw.js');

if (workbox) {

    workbox.core.skipWaiting();
    workbox.core.clientsClaim();

    workbox.precaching.precacheAndRoute(self.__WB_MANIFEST);

    let networkFirst = workbox.strategies.networkFirst({
        cacheExpiration: {
            maxAgeSeconds: 24 * 60 * 60,
            purgeOnQuotaError: true
        },
        plugins: [
            new workbox.cacheableResponse.Plugin({
                statuses: [0, 200],
            })
        ]
    });

    const networkFirstHandler = async (args) => {
        try {
            return await networkFirst.handle(args);
        } catch (error) { }
    };

    // Cache image requests for 1 day
    workbox.routing.registerRoute(
        new RegExp('https://(?:s1.thcdn|s2.thcdn|s3.thcdn|s4.thcdn|static.thcdn|static.thgcdn).com(?:/[^/#?]+)+\.(?:jpg|gif|png)$'),
        networkFirstHandler
    );

    // Cache JavaScript and CSS resources for 1 day
    workbox.routing.registerRoute(
        new RegExp('https://(?:s1.thcdn|s2.thcdn|s3.thcdn|s4.thcdn|static.thcdn|static.thgcdn).com(?:/[^/#?]+)+\.(?:js|css)$'),
        networkFirstHandler
    );

    // Cache others resources for 1 day
    workbox.routing.registerRoute(
        new RegExp('/.*'),
        networkFirstHandler
    );
}
